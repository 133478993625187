.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input{
  margin:1rem 1rem;
  padding: 0.5rem 0.5rem;
  font-size: 1.5rem;
  max-width: 66%;
  background-color: #282c34;
  color: white;
  border: solid 2px white;
}
button{
  color: white;
  background-color: #282c34;
  font-size: 1.5rem;
  font-weight:bold;
  border: solid 2px white;
  padding: 0.5rem 2rem;
  transition: all 0.3s;
}
button:hover{
  color: #282c34;
  background-color: white;
  border: solid 2px #282c34;
  transition: all 0.3s;
}